
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="电表名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入电表名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="表号" prop="meterNo">
          <el-input v-model="formModel.meterNo" placeholder="请输入表号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="单位号" prop="unitNo">
          <el-input v-model="formModel.unitNo" placeholder="请输入单位号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="所属终端" prop="terminalId">
          <el-select
            v-model="formModel.terminalId"
            filterable
            remote
            placeholder
            :remote-method="queryTerminal"
            style="width:300px"
          >
            <el-option
              v-for="terminal in terminalListFilter"
              :key="terminal.id"
              :label="terminal.remark"
              :value="terminal.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import electricMeterInfoApi from "@/api/base/electricMeterInfo";
import terminalApi from "@/api/base/terminalInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        meterNo: [{ required: true, message: "表号不能为空", trigger: "blur" }],
        unitNo: [
          { required: true, message: "单位号不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "电表名称不能为空", trigger: "blur" }
        ],
        terminalId: [
          { required: true, message: "所属终端不能为空", trigger: "blur" }
        ]
      },
      terminalList: [],
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  computed: {
    terminalListFilter() {
      var self = this;

      return self.terminalList.filter(terminal => {
        return terminal.id;
      });
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return electricMeterInfoApi.add(self.formModel);
            } else {
              return electricMeterInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    queryTerminal(keywords) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("parentType", this.parentType);
      formData.append("excludeId", this.formModel.id);
      formData.append("limit", 10);

      return terminalApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.terminalList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    self.loading = true;
    this.queryTerminal("");
    (function() {
      if (self.businessKey.length == 0) {
        return electricMeterInfoApi.create();
      } else {
        return electricMeterInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>